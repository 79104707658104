import {
    Heading,
    Button,
    Box,
    Text
} from 'grommet'

import {
    AppsRounded
} from 'grommet-icons'

import {
    useRecoilState
} from 'recoil'

import AppBar from './AppBar';
import sidebarState from '../recoil/sidebar';

const Header = () => {
    const [sidebar, setSidebar] = useRecoilState(sidebarState)

    const toggleSidebar = () => {
        setSidebar(prev => ({
            ...prev,
            open: !sidebar.open,
        }));
    }

    return (
        <Box
            background="brand"
            align="center"
            justify="center"
            basis="small"
            pad="medium"
            elevation="medium"
        >
            <Box
                direction="row"
                flex
                justify="center"
            >
                <Heading
                    color="lightText"
                    level='3'
                    margin="auto"
                >
                    CRYPTO DUST SHARE
                </Heading>
                {/* <Button
                    icon={<AppsRounded color="lightText" />}
                    onClick={toggleSidebar}
                /> */}
            </Box>
            <Box
                direction="row"
                flex
                justify="center"
            >
                <Text
                    color="lightText"
                    size="small"
                    margin="auto"
                >
                    web3 take a penny leave a penny tray
                </Text>
            </Box>

            <Box
                direction="row"
                flex
                justify="center"
            >
                <Button
                    primary
                    active
                    label="CONNECT WALLET"
                    color="light-4"
                    background="light-4"
                    onClick={() => {}}
                >
                </Button>
            </Box>

        </Box>
    )
}

export default Header