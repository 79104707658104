import { 
    Box ,
    Button
} from "grommet"
import CurrentBalance from "./CurrentBalance"

const BalanceBar = () => {
    return (
        <Box
                direction="row"
                height="small"
                justify="center"
            >
                <Box
                    height="small"
                    justify="end"
                    width="small"
                >
                    <Button
                        primary
                        label="SHARE"
                        margin="small"
                    />
                </Box>
                <CurrentBalance />
                <Box
                    height="small"
                    justify="end"
                    width="small"
                >
                    <Button
                        primary
                        label="TAKE"
                        margin="small"
                    />
                </Box>
            </Box>
    )
}

export default BalanceBar