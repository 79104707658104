import {
  Box,
  Collapsible,
  Grommet,
  Layer,
  ResponsiveContext,
  Button
} from 'grommet';
import {
  FormClose
} from 'grommet-icons'
import {
  useRecoilState,
} from 'recoil';
import {
  useContext
} from 'react'

import Home from './pages/Home';
import Header from './components/Header';
import sidebarState from './recoil/sidebar';

function App() {
  const [sidebar, setSidebar] = useRecoilState(sidebarState)
  const size = useContext(ResponsiveContext)

  const toggleSidebar = () => {
    setSidebar(prev => ({
      ...prev,
      open: !sidebar.open,
    }));
  }

  const theme = {
    global: {
      colors: {
        brand: {
          light: '#687FB7'
        },
        lightText: {
          light: '#FFF'
        }
      },
      font: {
        family: "\"Press Start 2P\"",
      },
      breakpoints: {
        xsmall: {
          value: 500,
        },
        small: {
          value: 900,
        },
        medium: undefined,
        middle: {
          value: 2000,
        },
      },
    },
    button: {
      default: {
        padding: {
          horizontal: '12px',
          vertical: '8px',
        },
      },
      primary: {
        background: {
          color: {
            light: "#C4C4C4"
          }
        }
      }
    }
  }

  // const theme = import("./Theme")

  return (
    <Grommet theme={theme} full>
      <ResponsiveContext.Consumer>
        {size => (
          <Box >
            <Header />
            <Box 
              direction='row' 
              overflow={{ horizontal: 'scroll' }}
            >
              <Box
                flex
                align='center'
                justify='top'
                background="#F3F4D5"
              >
                <Home />
              </Box>
              {(!sidebar.open || !['small', 'xsmall'].includes(size) ) ? (
                <Collapsible
                  direction="horizontal"
                  open={sidebar.open}>
                  <Box
                    flex
                    width='medium'
                    background='light-2'
                    align='center'
                    justify='center'
                    elevation='small'
                  >
                    sidebar not small [{size}]
                  </Box>
                </Collapsible>
              ) : (
                <Layer>
                  <Box
                    background='light-2'
                    tag='header'
                    justify='end'
                    align='center'
                    direction='row'
                  >
                    <Button
                      icon={<FormClose />}
                      onClick={toggleSidebar}
                    />
                  </Box>
                  <Box
                    fill
                    background='light-2'
                    align='center'
                    justify='center'
                  >
                    sidebar small [{size}]
                  </Box>
                </Layer>
              )}
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  )
}

export default App;
