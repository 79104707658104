import { 
    Box ,
    Button,
    Text
} from "grommet"

import BalanceBar  from "../components/BalanceBar"

const Home = () => {
    return (
        <Box
            direction="column"
        >
            <BalanceBar />
            <Box
                direction="row"
                justify="between"
            >
                <Box
                    width="medium"
                    justify="start"
                    background="light-4"
                    margin="small"
                    elevation="small"
                    direction="column"
                >
                    <Text
                        margin="small"
                    >
                        RECENT SHARES
                    </Text>
                    {
                        [
                            {
                                message: "Here's a little bit",
                                amount: 0.00034
                            },
                            {
                                message: "Here's a little bit",
                                amount: 0.00034
                            },
                            {
                                message: "Here's a little bit",
                                amount: 0.00034
                            },
                            {
                                message: "Here's a little bit",
                                amount: 0.00034
                            },
                            {
                                message: "Here's a little bit",
                                amount: 0.00034
                            },
                            {
                                message: "Here's a little bit",
                                amount: 0.00034
                            },
                            {
                                message: "Here's a little bit",
                                amount: 0.00034
                            },
                        ].map((share) => (
                        <Box
                            background="light-2"
                            direction="column"
                            border
                        >
                            <Text
                                margin={{top:"small", left:"small"}}
                                size="small"
                            >
                                {share.message}
                            </Text>
                            <Text
                                margin={{bottom:"small", left:"small"}}
                                size="small"
                            >{share.amount} SOL</Text>
                        </Box>
    
                        ))
                    }
                </Box>
                <Box
                    width="medium"
                    justify="start"
                    background="light-4"
                    margin="small"
                    elevation="small"
                    direction="column"
                >
                    <Text
                        textAlign="end"
                        margin="small"
                    >
                        RECENT TAKES
                    </Text>
                    {
                        [
                            {
                                message: "Thanks a lot!",
                                amount: 0.00005
                            },
                            {
                                message: "Thanks a lot!",
                                amount: 0.00005
                            },
                            {
                                message: "Thanks a lot!",
                                amount: 0.00005
                            },
                            {
                                message: "Thanks a lot!",
                                amount: 0.00005
                            },
                            {
                                message: "Thanks a lot!",
                                amount: 0.00005
                            },
                            {
                                message: "Thanks a lot!",
                                amount: 0.00005
                            },
                            {
                                message: "Thanks a lot!",
                                amount: 0.00005
                            },
                        ].map((share) => (
                        <Box
                            background="light-2"
                            direction="column"
                            border
                        >
                            <Text
                                margin={{top:"small", right:"small"}}
                                size="small"
                                textAlign="end"
                            >
                                {share.message}
                            </Text>
                            <Text
                                margin={{bottom:"small", right:"small"}}
                                size="small"
                                textAlign="end"
                            >{share.amount} SOL</Text>
                        </Box>
    
                        ))
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Home