import {
    atom
} from 'recoil'

const sidebarState = atom({
    key: 'sidebarState',
    default: {
        open: false,
    }
})

export default sidebarState;