import { 
    Box ,
    Text
} from "grommet"

const CurrentBalance = () => {
    return (
        <Box
            direction="column"
        >
            <Box
                background="brand"
                margin={{top:"medium"}}
                round={{corner:"top"}}
                align="center"
            >
                <Text
                    color="lightText"        
                    margin="medium"
                >
                    CURRENT BALANCE
                </Text>
            </Box>
            <Box
                background="white"
                margin={{bottom:"medium"}}
                border={{size:"large", color:"black"}}
                align="center"
            >
                <Text
                    margin="medium"
                >
                    1.02314 SOL
                </Text>
            </Box>
        </Box>
    )
}

export default CurrentBalance